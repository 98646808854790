label h2 p {
  -webkit-font-smoothing: antialiased;
}

@import './variables.scss';

@keyframes loading-horizontal {
  0% {
    background-position: -2000px 50%;
  }

  100% {
    background-position: 2000px 50%;
  }
}

$loading-gray: $qura-border;

.loading-horizontal {
  animation: loading-horizontal 1200ms linear infinite;
  background: linear-gradient(
    90deg,
    $loading-gray 0%,
    $qura-white 20%,
    $loading-gray 40%,
    $loading-gray 100%
  );

  background-size: 5000px 5000px;
}

@keyframes loading-vertical {
  0% {
    background-position: 50% -2000px;
  }

  100% {
    background-position: 50% 2000px;
  }
}

.loading-vertical {
  animation: loading-vertical 1200ms linear infinite;
  background: linear-gradient(180deg, white 0%, $loading-gray 20%, white 40%, white 100%);

  background-size: 5000px 5000px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

/* Removing the default appearance */
.date-filter-thumb,
.date-filter-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.date-filter-thumb-style {
  background-color: $qura-primary-text;
  border-radius: 50%;
  cursor: pointer;
  height: 0.75rem;
  width: 0.75rem;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
  @apply shadow;
}

/* For Chrome browsers */
.date-filter-thumb::-webkit-slider-thumb {
  @apply date-filter-thumb-style;
}

/* For Firefox browsers */
.date-filter-thumb::-moz-range-thumb {
  @apply date-filter-thumb-style;
}

/* Hide HTML5 Up and Down arrows on number inputs */

body {
  background: #fbfbfb;
}
