@font-face {
  font-family: 'Inter';
  src:
    local('Inter Thin'),
    url('./Inter-Thin.ttf') format('truetype');
  font-weight: 100; // Thin
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter ExtraLight'),
    url('./Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200; // ExtraLight
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Light'),
    url('./Inter-Light.ttf') format('truetype');
  font-weight: 300; // Light
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Regular'),
    url('./Inter-Regular.ttf') format('truetype');
  font-weight: 400; // Regular
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Medium'),
    url('./Inter-Medium.ttf') format('truetype');
  font-weight: 500; // Medium
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter SemiBold'),
    url('./Inter-SemiBold.ttf') format('truetype');
  font-weight: 600; // SemiBold
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Bold'),
    url('./Inter-Bold.ttf') format('truetype');
  font-weight: 700; // Bold
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter ExtraBold'),
    url('./Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800; // ExtraBold
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Black'),
    url('./Inter-Black.ttf') format('truetype');
  font-weight: 900; // Black
}
