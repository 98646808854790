$searchBarHeight: 50px;
$gray-600: #4b5563;
$gray-500: #6b7280;
$gray-400: #9ca3af;
$gray-300: #d1d5db;
$cardGray: #f3f6fb;
$cardGrayHover: #e3e8f0;

$qura-bg: #fbfbfb;
$qura-white: #ffffff;
$qura-red: #d30b0b;
$qura-primary-text: #000000;
$qura-secondary-text: #808080;
$qura-ternary-text: #bbbbbb;
$qura-border: #dddddd;
$qura-btn-hover: #f8f8f8;
